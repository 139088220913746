export const locales = [{
	code: 'en',
	nativeLabel: 'English',
	englishLabel: 'English'
}, {
	code: 'de',
	nativeLabel: 'Deutsch',
	englishLabel: 'German'
}, {
	code: 'pt_BR',
	nativeLabel: 'Português brasileiro',
	englishLabel: 'Portuguese (Brazil)'
}]
