import { render, staticRenderFns } from "./poll.vue?vue&type=template&id=a1f1163c&lang=pug&"
import script from "./poll.vue?vue&type=script&lang=js&"
export * from "./poll.vue?vue&type=script&lang=js&"
import style0 from "./poll.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports