<template lang="pug">
.c-dashboard-layout-panel
	slot
</template>
<script>
export default {
}
</script>
<style lang="stylus">
.c-dashboard-layout-panel
	display: flex
	flex-direction: column
	min-height: 0
</style>
